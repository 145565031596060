@import 'variables';

@mixin for-sm-up {
  @media (min-width: $breakpoint-sm) {
    @content;
  }
}

@mixin for-md-up {
  @media (min-width: $breakpoint-md) {
    @content;
  }
}

@mixin for-md-down {
  @media (max-width: $breakpoint-md) {
    @content;
  }
}

@mixin for-lg-up {
  @media (min-width: $breakpoint-lg) {
    @content;
  }
}

@mixin for-xl-up {
  @media (min-width: $breakpoint-xl) {
    @content;
  }
}