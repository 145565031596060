@import '@styles/mediaqueries';

.skills {
  width: 100%;
  overflow: hidden;

  &__content-pair {
    display: flex;
    flex-wrap: wrap;

    &__tree {
      width: 100%;
    }

    &__content {
      margin-top: 0.4rem;
    }
  }

  @include for-sm-up {
    &__content-pair {
      &__tree {
        width: auto;
      }

      &__content {
        margin-left: 0.5rem;
      }
    }
  }

  .special-links {
    span {
      text-align: left;
      display: inline-block;
      max-width: calc(100vw - 148px);
      overflow: hidden;
      padding-left: 1px;
      text-overflow: ellipsis;
    }
  }
}
