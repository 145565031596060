// Global style helpers
.flex {
  align-items: center;
  display: flex;
  justify-content: center;
}

p {
  b {
    font-weight: bold;
  }
}