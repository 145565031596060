@import '@styles/mediaqueries';

.navigation {
  $root: &;

  #{$root}__link {
    &--active {
      color: #fefefe;
      background: #060084;
      cursor: default;
    }
  }

  &__list {
    @include for-md-down {
      justify-content: space-between;
    }
  }
}
