@import '@styles/mediaqueries';

.home {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 100%;

  &__main {
    height: 100%;
    flex-direction: column;
  }

  h2 {
    font-size: 1.3rem;
  }

  p {
    font-size: 1rem;
  }

  @include for-sm-up {
    h2 {
      font-size: 2rem;
    }
  }
}