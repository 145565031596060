.window-content-layout {
  $root: &;

  display: flex !important;
  height: 100%;
  justify-content: center;
  width: 100%;

  #{$root}__main {
    max-width: 1080px;
    padding: 1rem;
    width: 100%;
  }
}